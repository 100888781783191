import { ServiceStatus } from "../enums/service.status";
import { ResponseServiceTemplate } from "./response.service.template";
import { ResponseBaseModel } from "./response.base.model";
import { ServiceTaskResultModel } from "../models/service.task.result.model";
import { Type } from "class-transformer";
import { ContactModel } from "../models/contact.model";
import { ResponseTemplatesSetModel } from "./response.templates.set.model";
import { ServiceCommentModel } from "@/models/response.models/service.comment.model";
import { ResponseEventLogModel } from "@/models/response.models/response.eventLog.model";
import ServiceTaskResultHeaderModel from "@/models/models/service.task.result.header";
import BaseModel from "@/models/models/base/base.model";
import ServiceSignatureModel from "../models/service.signature.model";

export class ResponseServiceModel extends ResponseBaseModel {
    serviceId!: string;

    name!: string;

    status!: ServiceStatus;

    necessity!: BaseModel;

    type!: BaseModel;

    typeName!: string;

    @Type(() => Date)
    reportEndDate!: Date;

    @Type(() => Date)
    recommendedServiceDate!: Date;

    get recommendedServiceDateString(): string {
        return this.recommendedServiceDate.toLocaleDateString();
    }

    serviceProvider!: ContactModel;

    location?: string = "";

    requestedBy?: string = "";

    serviceTemplate!: ResponseServiceTemplate;

    scheduledDateTimeLocalized!: string;

    serviceProviderName!: string;

    taskResults!: ServiceTaskResultModel[];

    isCurrentService!: boolean;

    vesselId!: number;

    vesselNumber?: string;
    
    vesselName?: string;

    serviceProviderId!: number;

    secondaryServiceProviderId?: number;

    isSetTemplate!: boolean;

    templateSetsId!: number;

    templateSets!: ResponseTemplatesSetModel;

    comments!: ServiceCommentModel[];

    isDockingConfirmed!: boolean;

    isServiceProviderConfirmed!: boolean;

    isSparePartsSent!: boolean;

    isSparePartsByOtherParty!: boolean;

    isUpcoming!: boolean;

    connectedEventLogs?: ResponseEventLogModel[];

    serviceTaskResultHeaders?: ServiceTaskResultHeaderModel[];

    standAloneTaskResults?: ServiceTaskResultModel[];

    handlerId?: number;

    secondaryHandlerId?: number;

    @Type(() => Date)
    dockingDateEnd!: Date;

    @Type(() => Date)
    dockingDateStart!: Date;

    @Type(() => Date)
    lastTechnicianUpdate?: Date;

    serviceScopeBasedOnVesselJets!: number;

    concatenatedInvolvedPersonnel?: string;

    signature?: ServiceSignatureModel;
}
