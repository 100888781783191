import { DataTableFilterMeta, DataTablePageEvent } from "primevue/datatable";
import DataTable from "primevue/datatable";
import { MenuItem } from "primevue/menuitem";
import { Component, Emit, Prop, Watch } from "vue-facing-decorator";
import { TableModel } from "@/models/table/table.model";
import { ColumnModel } from "@/models/table/column.model";
import { FieldType } from "@/models/table/field.type";
import Tooltip from "primevue/tooltip";
import _ from "lodash";
import BaseControl from "../../complex.controls/base.control";
import ColumnsChangeDialogComponent from "./columns.change.dialog/columns.change.dialog.vue";
import { Key } from "ts-key-enum";
import { IDropDownOptions } from "../colored.dropdown/colored.dropdown";
import AdvancedSearchDialogComponent from "./advanced.search.dialog/advanced.search.dialog.vue";
import AdvancedSearchDateFieldModel from "@/models/models/advanced.search.date.field.model";

@Component({
    components: {
        ColumnsChangeDialogComponent,
        AdvancedSearchDialogComponent
    },
    directives: {
        Tooltip
    }
})
export default class DataTableComponent extends BaseControl {
    @Prop()
    table!: TableModel;

    @Prop({ default: 10 })
    pageSize!: number;

    @Prop()
    value!: { id: number; [others: string]: unknown }[];

    @Prop()
    uniqueSettingsKey!: string;

    @Prop({ default: false })
    isLoading!: boolean;

    @Prop()
    loadingText!: string;

    @Prop()
    errorText!: string;

    @Prop()
    dataError!: Error[];

    @Prop({ default: false })
    disableAutofocus!: boolean;

    @Prop({ default: false })
    showBulkAction!: boolean;

    @Prop({ default: true })
    showGroupAction!: boolean;

    @Prop({ default: false })
    showDropdownFilter!: boolean;

    @Prop({ default: "Filter by" })
    dropdownFilterButtonText?: string;

    @Prop({ default: true })
    showGlobalSearch!: boolean;

    @Prop({ default: false })
    showServerSearch!: boolean;

    @Prop({ default: false })
    serverExport!: boolean;

    @Prop()
    selectedItems!: { id: number }[] | null;

    @Prop({ default: true })
    showSelect!: boolean;

    @Prop({ default: [] })
    hintList: IDropDownOptions[] = [];

    @Prop({ default: false })
    overrideButtonBackgroundToPrimary!: boolean;

    @Prop({ default: "" })
    searchQueryKey!: string;

    @Prop()
    dateFields!: AdvancedSearchDateFieldModel[];

    @Prop()
    pageKey?: string;

    @Prop()
    extendedFilterFields?: string[];

    selectedItemsInternal: { id: number }[] = [];
    tableInternal!: TableModel;

    fieldType = FieldType;
    selected: { [name: string]: number } = {};
    searchQuery: string[] = [];

    groupRowsBy: string | null = null;
    expandableRowGroups = false;

    groupByMenuItems: MenuItem[] = [];
    bulkActionMenuItems: MenuItem[] = [];
    dropdownFilterMenuItems: MenuItem[] = [];
    filters: DataTableFilterMeta | null = {};
    expandedRowGroups = null;
    globalFilterFields: string[] = [];
    paginator = true;
    currentPage: number = 0;
    updateKey = 0;
    updateCheckboxKeys = {};
    selectedHint: IDropDownOptions = this.hintList[0];

    declare $refs: {
        table: DataTable;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        searchControl: any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        chips: any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        fieldsPanel: any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        fieldsPanelList: any;
    };

    @Emit("selection")
    selectItems($event: { id: number }[]) {
        this.selectedItemsInternal = $event;
        return $event;
    }

    @Emit("search")
    onSearch(searchQuery: string[]) {
        return searchQuery;
    }

    @Emit("search")
    clear() {
        this.searchQuery = [];
        localStorage.setItem(this.searchQueryKey, "");
        return this.searchQuery;
    }

    @Watch("selectedItems")
    selectedItemChanged() {
        const ids = this.selectedItems?.map(si => si.id);
        this.selectedItemsInternal = this.value?.filter(i => ids?.includes(i.id));
    }

    created() {
        this.tableInternal = _.cloneDeep(this.table);
        this.filters = this.table.filters;
        const savedQuery = localStorage.getItem(this.searchQueryKey);
        if (savedQuery && savedQuery !== "[]") {
            this.searchQuery = JSON.parse(savedQuery);
            this.search();
        }
        const savedColumns = localStorage.getItem(this.uniqueSettingsKey);
        if (savedColumns) {
            const columns = JSON.parse(savedColumns);
            this.appendColumnsWithData(columns);
            this.table.columns = columns;
        }

        this.table.columns.forEach((column: ColumnModel) => {
            if (column.groupField) {
                this.groupByMenuItems.push({
                    label: column.header,
                    icon: "pi",
                    command: () => this.groupBy(column.fieldName as string)
                });
            }

            if (column.fieldName) {
                this.globalFilterFields.push(column.fieldName as string);
            }
        });

        if (this.extendedFilterFields) this.globalFilterFields = [...this.globalFilterFields, ...this.extendedFilterFields];
    }

    mounted() {
        this.selectedItemChanged();
        if (!this.disableAutofocus) {
            if (this.showGlobalSearch) {
                // this.$nextTick(() => this.$refs.searchControl?.$el?.focus()); this will also scroll down to this component
            } else {
                this.$nextTick(() => this.$refs.chips?.$el?.children[0]?.children[0]?.children[0]?.focus());
            }
        }
        if (this.pageKey) this.currentPage = Number(localStorage.getItem(this.pageKey));
    }

    select(data: string): void {
        if (this.selected[data]) {
            const selected = this.value.filter(v => v[this.groupRowsBy as string] == data);
            this.selectedItemsInternal = [...this.selectedItemsInternal, ...selected];
        } else {
            const unselected = this.value.filter(v => v[this.groupRowsBy as string] == data);
            this.selectedItemsInternal = this.selectedItemsInternal.filter(v => !unselected.find(us => us.id == v.id));
        }
    }

    getData(data: unknown, field: string) {
        return _.get(data, field);
    }

    onClickCancelGrouping() {
        this.groupRowsBy = null;
        this.expandableRowGroups = false;
        this.expandedRowGroups = null;
        this.paginator = true;
    }

    groupBy(columnName: string) {
        this.updateKey++;
        this.expandedRowGroups = null;
        this.groupRowsBy = columnName;
        this.paginator = false;
        this.expandableRowGroups = true;
    }

    editChip(element: { value: string }) {
        this.$refs.chips.$refs.input.value = element.value;
        this.searchQuery = this.searchQuery.filter(sq => sq != element.value);
    }

    search() {
        const beforeUniqLength = this.searchQuery.length;
        this.searchQuery = this.searchQuery.map(q => q.trim()).filter(q => !!q);
        this.searchQuery = _.uniqWith(this.searchQuery, (a, b) => a.toLowerCase() === b.toLowerCase());
        if (beforeUniqLength > this.searchQuery.length) {
            return;
        }

        this.searchQuery = this.searchQuery.map(i => {
            if (i.includes(":")) {
                const split = i.split(":");
                return split[0] + ":" + split[1].trim();
            }
            return i;
        });

        localStorage.setItem(this.searchQueryKey, JSON.stringify(this.searchQuery));
        this.onSearch(this.searchQuery);
    }

    searchInputKeyDown(event: KeyboardEvent): void {
        if (event.key == Key.Escape) this.$refs.fieldsPanel.hide();

        if (this.$refs.fieldsPanelList) {
            if (event.key == Key.ArrowDown) {
                const index = this.$refs.fieldsPanelList.visibleOptions.indexOf(this.selectedHint);
                this.selectedHint = this.$refs.fieldsPanelList.visibleOptions[Math.min(index + 1, this.$refs.fieldsPanelList.visibleOptions.length - 1)];
            } else if (event.key == Key.ArrowUp) {
                const index = this.$refs.fieldsPanelList.visibleOptions.indexOf(this.selectedHint);
                this.selectedHint = this.$refs.fieldsPanelList.visibleOptions[Math.max(index - 1, 0)];

                // workaround to fix the problem of moving cursor to the beginning of input
                this.$refs.chips.$refs.input.blur();
                setTimeout(() => this.$refs.chips.$refs.input.focus(), 0);
            }

            this.$nextTick(() => {
                const selectedElement = this.$refs.fieldsPanelList.$el.querySelector('.p-listbox-item.p-highlight');

                if (selectedElement) selectedElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            });
        }

        // to block new chip creation
        if (this.$refs.fieldsPanel.visible) event.stopImmediatePropagation();
    }

     searchInputKeyUp(event: KeyboardEvent): void {
        if (event.key == Key.Enter && !this.$refs.fieldsPanel.visible) {
            this.search();
        } else if (event.key == Key.Enter && this.$refs.fieldsPanel.visible) {
            //add selected hint in search field
            this.$refs.chips.$refs.input.value = `/${this.selectedHint.value}:`;
            this.$refs.fieldsPanel.hide();
        } else if (event.key != Key.Escape && this.$refs.chips.inputValue?.startsWith("/") && !this.$refs.chips.inputValue?.includes(":")) {
            if (!this.$refs.fieldsPanel.visible) {
                this.$refs.fieldsPanel.show(event);
            }

            this.$nextTick(() => {
                if (event.key != Key.ArrowDown && event.key != Key.ArrowUp && this.$refs.fieldsPanelList) {
                    this.$refs.fieldsPanelList.filterValue = this.$refs.chips.inputValue.substring(1);
                    if (this.$refs.fieldsPanelList.visibleOptions?.length) {
                        this.selectedHint = this.$refs.fieldsPanelList.visibleOptions[0];
                    } else {
                        this.$refs.fieldsPanel.hide();
                    }
                }
            });
        } else {
            this.$refs.fieldsPanel.hide();
        }
    }

    hintItemChanged(newSelection: IDropDownOptions) {
        //select item by mouse click
        if (!this.selectedHint) {
            return;
        }

        this.$refs.chips.$refs.input.value = `/${(newSelection ?? this.selectedHint).value}:`; //this hint to block unselect event on mouse click
        this.$refs.fieldsPanel.hide();
        setTimeout(() => {
            this.$refs.chips.$refs.input.focus();
        }, 0);
    }

    hideColumnsSettings() {
        this.setSubComponent("ColumnsChangeDialogComponent", {
            columns: this.table.columns,
            storageKey: this.uniqueSettingsKey,
            confirmCallback: (columns: ColumnModel[]) => {
                if (columns) {
                    this.appendColumnsWithData(columns);
                    this.table.columns = columns;
                } else {
                    this.table.columns = _.cloneDeep(this.tableInternal.columns);
                }

                this.updateKey++;
            }
        });
    }

    advancedSearch() {
        this.setSubComponent("AdvancedSearchDialogComponent", {
            query: this.searchQuery,
            hintList: this.hintList,
            confirmCallback: (query: string[]) => {
                this.searchQuery = query;
                this.search();
            },
            dateFields: this.dateFields
        });
    }

    getWidth(columnDefinition: ColumnModel): string {
        if (this.isMobileView) {
            return "";
        }

        if (columnDefinition.fieldType == FieldType.IconAction) {
            return "max-width:50px; min-width:50px";
        } else if (columnDefinition.width == "flex") {
            return "min-width:200px";
        } else if (columnDefinition.width) {
            return `max-width:${columnDefinition.width}; min-width:${columnDefinition.width}`;
        } else {
            return "max-width:200px; min-width:200px";
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toggle(event: Event, key: string, refs: any) {
        event.stopImmediatePropagation();
        refs[`menu${key}`][0].toggle(event);
    }

    onPaginate(page: DataTablePageEvent): void {
        if (this.pageKey) localStorage.setItem(this.pageKey, page.page.toString());
    }

    private appendColumnsWithData(columns: ColumnModel[]): void {
        columns.forEach(c => {
            if (c.additionalData) {
                const existCol = this.table.columns.find(cc => cc.fieldName == c.fieldName && cc.fieldType == c.fieldType);
                if (existCol) c.additionalData = existCol.additionalData;
            }
        });
    }
}
