import { Store } from "vuex";
import { EventLogManager } from "./event.log.manager";
import { UserManager } from "./user.manager";
import { VesselManager } from "./vessel.manager";
import { ServiceManager } from "./service.manager";
import { OperatingProfileManager } from "./operating.profile.manager";
import { SoftwareVersionManager } from "./software.version.manager";
import { AttachmentManager } from "./attachment.manager";
import { ServiceCommentManager } from "./service.comment.manager";
import { ServiceTaskManager } from "./service.task.manager";
import { PartManager } from "./part.manager";
import { TaskMultiMediaManager } from "./task.multi.media.manager";
import { DictionaryDataManager } from "./dictionary.data.manager";
import { IState } from "@/store";
import { ContactManager } from "./contact.manager";
import { OperatorManager } from "./operator.manager";
import { TemplateManager } from "@/managers/template.manager";

export interface IApi {
    userManager: UserManager;
    vesselManager: VesselManager;
    eventLogManager: EventLogManager;
    contactManager: ContactManager;
    serviceManager: ServiceManager;
    serviceTaskManager: ServiceTaskManager;
    operatingProfileManager: OperatingProfileManager;
    softwareVersionManager: SoftwareVersionManager;
    attachmentManager: AttachmentManager;
    taskAttachmentManager: TaskMultiMediaManager;
    serviceCommentManager: ServiceCommentManager;
    partManager: PartManager;
    dictionaryDataManager: DictionaryDataManager;
    operatorManager: OperatorManager;
    templateManager: TemplateManager;
}

export default (store: Store<IState>) => {
    const api: IApi = {
        userManager: new UserManager(),
        vesselManager: new VesselManager(),
        eventLogManager: new EventLogManager(),
        contactManager: new ContactManager(),
        serviceManager: new ServiceManager(),
        serviceTaskManager: new ServiceTaskManager(),
        operatingProfileManager: new OperatingProfileManager(),
        softwareVersionManager: new SoftwareVersionManager(),
        attachmentManager: new AttachmentManager(),
        taskAttachmentManager: new TaskMultiMediaManager(),
        serviceCommentManager: new ServiceCommentManager(),
        partManager: new PartManager(),
        dictionaryDataManager: new DictionaryDataManager(),
        operatorManager: new OperatorManager(),
        templateManager: new TemplateManager()
    };

    Object.assign(store, api);
};
