import { Component } from "vue-facing-decorator";
import BaseControl from "../../base.control";
import eventBus, { resetEvent, prevSectionEvent, nextSectionEvent, subMenuItemsEvent, activeSubMenuDotIndexEvent } from "@/event/event.bus";
import { authModule } from "@/store/auth.module";
import { isAdmin } from "@/utilities/helpers/user.role.helper";
import FeedbackFormComponent from "../../feedback.form/feedback.form.vue";

@Component({ components: { FeedbackFormComponent } })
export default class NavbarComponent extends BaseControl {
    subMenuItems: { label: string; iconSrc: any; action: () => void }[] = [];
    activeDotIndex: number = 0;

    isSectionMenuExpanded: boolean = false;
    isUserMenuExpanded: boolean = false;

    sectionMenuId: string = "section-menu";
    userMenuId: string = "user-menu";

    initialSectionMenuHeight: number = 0;
    initialUserMenuHeight: number = 0;

    sectionMenuHeight: number = 0;
    userMenuHeight: number = 0;

    touchStartY: number = 0;

    activeRouteIndex: number = 4;

    get userFullName(): string {
        return authModule.currentUser?.fullName ?? "";
    }

    get userEmail(): string {
        return authModule.currentUser?.email ?? "";
    }

    get routeItems(): { index: number; label: string; icon?: string; command?: () => Promise<void>; to: any }[] {
        const logout = {
            index: 0,
            label: "Logout",
            icon: "pi mx-1 pi-sign-out",
            command: async () => await this.logout()
        };

        const currentAgent = {
            index: 2,
            label: this.$t("navbar.agent"),
            to: `/service-agent/${authModule.currentUser?.serviceProviderId}`
        };

        const fleet = { index: 3, label: this.$t("navbar.fleet"), to: "/fleet" };
        const events = { index: 4, label: this.$t("navbar.events"), to: "/" };
        const services = { index: 5, label: this.$t("navbar.serviceReport"), to: "/serviceReports" };
        const serviceAgents = { index: 6, label: this.$t("navbar.serviceProviders"), to: "/service-agents" };
        const admin = { index: 7, label: this.$t("navbar.admin"), to: "/admin" };

        return isAdmin(authModule.currentUser?.role) ? [fleet, events, services, serviceAgents, admin] : [currentAgent];
    }

    get isAdmin(): boolean {
        return isAdmin(authModule.currentUser?.role);
    }

    get version(): string {
        return `Version ${process.env.VUE_APP_VERSION}`;
    }

    get logoutItem(): { index: number; label: string; icon: string; command: () => Promise<void> } {
        return {
            index: 0,
            label: "Logout",
            icon: "pi mx-1 pi-sign-out",
            command: async () => await this.logout()
        };
    }

    created(): void {
        eventBus.on(subMenuItemsEvent, args => (this.subMenuItems = args.items));
        eventBus.on(activeSubMenuDotIndexEvent, args => (this.activeDotIndex = args.index));
        eventBus.on(resetEvent, _ => (this.activeDotIndex = 0));
    }

    onMenuItemClicked(action: () => void): void {
        this.isSectionMenuExpanded = false;
        action();
    }

    onPrevSection(): void {
        eventBus.emit(prevSectionEvent, {});
    }

    onNextSection(): void {
        eventBus.emit(nextSectionEvent, {});
    }

    expandSectionMenu(): void {
        if (this.subMenuItems.length === 0) return;

        this.isSectionMenuExpanded = !this.isSectionMenuExpanded;
        this.isUserMenuExpanded = false;
    }

    expandUserMenu(): void {
        this.isUserMenuExpanded = !this.isUserMenuExpanded;
        this.isSectionMenuExpanded = false;
    }

    onTouchStart(event: TouchEvent, id: string): void {
        this.touchStartY = event.touches[0].clientY;

        const menu = document.getElementById(id) as HTMLElement;

        if (this.isSectionMenu(id)) this.initialSectionMenuHeight = menu.clientHeight;
        else this.initialUserMenuHeight = menu.clientHeight;
    }

    onTouchMove(event: TouchEvent, id: string): void {
        const initialHeight = this.isSectionMenu(id) ? this.initialSectionMenuHeight : this.initialUserMenuHeight;

        const deltaY = event.touches[0].clientY - this.touchStartY;

        const newHeight = deltaY > 0 ? initialHeight - deltaY : initialHeight;

        if (this.isSectionMenu(id)) this.sectionMenuHeight = Math.max(newHeight, 0);
        else this.userMenuHeight = Math.max(newHeight, 0);

        if (newHeight <= 80) {
            this.isSectionMenuExpanded = false;
            this.isUserMenuExpanded = false;

            if (this.isSectionMenu(id)) this.sectionMenuHeight = initialHeight;
            else this.userMenuHeight = initialHeight;
        }
    }

    onTouchEnd(_: TouchEvent, id: string): void {
        if (this.isSectionMenu(id)) {
            if (this.sectionMenuHeight > 80) {
                this.sectionMenuHeight = this.initialSectionMenuHeight;
            }
        } else {
            if (this.userMenuHeight > 80) {
                this.userMenuHeight = this.initialUserMenuHeight;
            }
        }
    }

    async logout(): Promise<void> {
        await authModule.logout();
        this.$router.go(0);
    }

    minimizeAllMenus(): void {
        if (this.isSectionMenuExpanded) this.sectionMenuHeight = this.initialSectionMenuHeight;
        else this.userMenuHeight = this.initialUserMenuHeight;

        this.isSectionMenuExpanded = false;
        this.isUserMenuExpanded = false;
    }

    showFeedbackForm() {
        this.setSubComponent("FeedbackForm", {});
    }

    private isSectionMenu(id: string): boolean {
        return id === this.sectionMenuId;
    }
}
