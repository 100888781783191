import { Action, getModule, Module, Mutation } from "vuex-module-decorators";
import { BaseModule } from "./base.module";
import store, { IStore } from "./index";
import { ResponseFleetVesselModel } from "@/models/response.models/response.fleet.vessel.model";
import Query from "@/models/models/query";

export interface IFleetState {
    vessels: ResponseFleetVesselModel[];
    lowClassVessels: ResponseFleetVesselModel[];
}

@Module({
    store,
    name: "fleet",
    dynamic: true,
    namespaced: true
})
class FleetModule extends BaseModule implements IFleetState {
    private readonly store!: IStore;

    
    private fleetVessels!: ResponseFleetVesselModel[];
    private lowClass!: ResponseFleetVesselModel[];

    get vessels(): ResponseFleetVesselModel[] {
        return this.fleetVessels;
    }

    get lowClassVessels(): ResponseFleetVesselModel[] {
        return this.lowClass;
    }

    @Mutation
    public async setIsFleetLoading(value: boolean) {
        this.isLoading = value;
    }

    @Mutation
    public async setFetchFleetSuccessful(fleetVessels: ResponseFleetVesselModel[]) {
        this.fleetVessels = fleetVessels;
        this.isLoading = false;
    }

    @Mutation
    public async setFetchFleetUnsuccessful(error: Error) {
        this.errors.push(error);
        this.isLoading = false;
    }

    @Mutation
    public async resetState() {
        this.fleetVessels = [];
        this.lowClass = [];
        this.isLoading = false;
    }

    @Action
    public async reset() {
        return this.context.commit("resetState");
    }

    @Action
    public async fetchFleet(query: Query) {
        this.context.commit("setIsFleetLoading", true);
        const response = await this.store.vesselManager.getFleet(query);

        if (response instanceof Error) return this.context.commit("setFetchFleetUnsuccessful", response);

        return this.context.commit("setFetchFleetSuccessful", response);
    }

    @Mutation
    public async setFetchLowClassFleetSuccessful(vessels: ResponseFleetVesselModel[]) {
        this.lowClass = vessels;
        this.isLoading = false;
    }

    @Mutation
    public async setFetchLowClassFleetUnsuccessful(error: Error) {
        this.errors.push(error);
        this.isLoading = false;
    }

    @Action
    public async fetchLowClassFleet(query: Query) {
        this.context.commit("setIsFleetLoading", true);
        const response = await this.store.vesselManager.getFleet(query, false);

        if (response instanceof Error) return this.context.commit("setFetchLowClassFleetUnsuccessful", response);

        return this.context.commit("setFetchLowClassFleetSuccessful", response);
    }
}

export const fleetModule: FleetModule = getModule(FleetModule);
