import { AxiosInstance, AxiosResponse } from "axios";
import httpclient from "../utilities/helpers/http.client.axios";
import { ServiceTaskModel } from "@/models/models/service.task.model";
import { ServiceTaskResultModel } from "@/models/models/service.task.result.model";
import RequestBulkServiceTaskResultModel from "@/models/request.models/request.bulk.service.task.result.model";
import TaskCategoryModel from "@/models/models/task/task.category.model";
import TaskSectionModel from "@/models/models/task/task.section.model";
import BaseModel from "@/models/models/base/base.model";
import ServiceTaskResultHeaderModel from "@/models/models/service.task.result.header";

export class ServiceTaskManager {
    private readonly api: AxiosInstance = httpclient;

    public async removeTaskResult(taskResultId: number) {
        try {
            const response = await this.api.delete(`/ServiceTask/RemoveTaskResult/${taskResultId}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getServiceTasks(): Promise<ServiceTaskModel[] | Error> {
        try {
            const response: AxiosResponse<ServiceTaskModel[]> = await this.api.get(`/ServiceTask/GetServiceTasks`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getCustomServiceTasksAsync(): Promise<ServiceTaskModel[] | Error> {
        try {
            const response: AxiosResponse<ServiceTaskModel[]> = await this.api.get(`/ServiceTask/GetCustomServiceTasks`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async createServiceTaskResultAsync(taskResults: ServiceTaskResultModel): Promise<ServiceTaskResultModel | Error> {
        try {
            const response: AxiosResponse<ServiceTaskResultModel> = await this.api.post(`/ServiceTask/CreateServiceTaskResult`, taskResults);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async createServiceTaskResultsAsync(requests: ServiceTaskResultModel[]): Promise<unknown | Error> {
        try {
            const response: AxiosResponse<unknown> = await this.api.post(`/ServiceTask/CreateServiceTaskResults`, requests);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async createHeadersAndTasksAsync(requests: ServiceTaskResultHeaderModel[]): Promise<unknown | Error> {
        try {
            const response: AxiosResponse<unknown> = await this.api.post(`/ServiceTask/CreateHeadersAndTasks`, requests);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async updateServiceTaskResultAsync(taskResult: ServiceTaskResultModel): Promise<unknown | Error> {
        try {
            const response: AxiosResponse<unknown> = await this.api.put(`/ServiceTask/UpdateServiceTaskResult`, taskResult);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async changeServiceTaskResultIndexAsync(taskResults: ServiceTaskResultModel[]): Promise<unknown | Error> {
        try {
            const response: AxiosResponse<unknown> = await this.api.put(`/ServiceTask/ChangeIndex`, taskResults);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async changeServiceHeaderIndexAsync(taskResults: ServiceTaskResultHeaderModel[]): Promise<unknown | Error> {
        try {
            const response: AxiosResponse<unknown> = await this.api.put(`/ServiceTask/ChangeHeaderIndex`, taskResults);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async addTaskToHeaderAsync(request: ServiceTaskResultHeaderModel): Promise<unknown | Error> {
        try {
            const response: AxiosResponse<unknown> = await this.api.post(`/ServiceTask/AddTaskToHeader`, request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async detachHeaderTaskAndReorderAsync(request: ServiceTaskResultModel): Promise<void | Error> {
        try {
            const response: AxiosResponse<void> = await this.api.post(`/ServiceTask/DetachTaskAndReorder`, request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async addHeaderAsync(request: ServiceTaskResultHeaderModel): Promise<ServiceTaskResultHeaderModel | Error> {
        try {
            const response: AxiosResponse<ServiceTaskResultHeaderModel> = await this.api.post(`/ServiceTask/AddHeader`, request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async removeHeaderAsync(request: ServiceTaskResultHeaderModel): Promise<unknown | Error> {
        try {
            const response: AxiosResponse<ServiceTaskResultHeaderModel> = await this.api.post(`/ServiceTask/RemoveHeader`, request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async updateHeaderAsync(request: ServiceTaskResultHeaderModel): Promise<unknown | Error> {
        try {
            const response: AxiosResponse<unknown> = await this.api.put(`/ServiceTask/UpdateHeader`, request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async bulkCreateServiceTaskResultsAsync(requests: ServiceTaskResultModel[]): Promise<unknown | Error> {
        try {
            const response: AxiosResponse<unknown> = await this.api.post(`/ServiceTask/BulkCreateServiceTaskResults`, requests);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async bulkCreateServiceTaskResultAsync(taskResults: RequestBulkServiceTaskResultModel): Promise<ServiceTaskResultModel[] | Error> {
        try {
            const response: AxiosResponse<ServiceTaskResultModel[]> = await this.api.post(`/ServiceTask/BulkCreateServiceTaskResult`, taskResults);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getTaskCategoriesAsync(): Promise<TaskCategoryModel[] | Error> {
        try {
            const response: AxiosResponse<TaskCategoryModel[]> = await this.api.get("/ServiceTask/GetTaskCategories");
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getTaskSectionsAsync(): Promise<TaskSectionModel[] | Error> {
        try {
            const response: AxiosResponse<TaskSectionModel[]> = await this.api.get("/ServiceTask/GetTaskSections");
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getTaskCompletionTypesAsync(): Promise<BaseModel[] | Error> {
        try {
            const response: AxiosResponse<BaseModel[]> = await this.api.get("/ServiceTask/TaskCompletionTypes");
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getTaskCompletionLevelsAsync(): Promise<BaseModel[] | Error> {
        try {
            const response: AxiosResponse<BaseModel[]> = await this.api.get("/ServiceTask/TaskCompletionLevels");
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getTaskAsync(id: number): Promise<ServiceTaskModel | Error> {
        try {
            const response: AxiosResponse<ServiceTaskModel> = await this.api.get(`/ServiceTask/Task/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async createTaskAsync(request: ServiceTaskModel): Promise<ServiceTaskModel | Error> {
        try {
            const response: AxiosResponse<ServiceTaskModel> = await this.api.post(`/ServiceTask/Create`, request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async updateTaskAsync(request: ServiceTaskModel): Promise<ServiceTaskModel | Error> {
        try {
            const response: AxiosResponse<ServiceTaskModel> = await this.api.put(`/ServiceTask/Update`, request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async removeTaskAsync(id: number): Promise<unknown | Error> {
        try {
            const response: AxiosResponse<unknown> = await this.api.delete(`/ServiceTask/Delete/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }
}

export const serviceTaskManager = new ServiceTaskManager();
