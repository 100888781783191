import BaseControl from "@/components/complex.controls/base.control";
import { Component, Prop, Emit } from "vue-facing-decorator";

export interface IDropDownOptions {
    value: string | number;
    text: string;
    disabled?: boolean;
}

@Component({})
export default class ColoredDropdownComponent extends BaseControl {
    @Prop() options!: IDropDownOptions[];

    @Prop() coloredDropdownColors?: { value: number; imageSource: string }[];

    @Prop({ default: null }) modelValue!: string | null;

    @Prop({ default: false }) disabled!: boolean;

    get selection() {
        return this.modelValue;
    }
    set selection(value) {
        this.onUpdate(value);
    }

    @Emit
    updateValue() {
        return;
    }

    @Emit("update:modelValue")
    onUpdate(value: string | null) {
        return value;
    }

    stopExpandEvent(event: PointerEvent): void {
        event.stopImmediatePropagation();
    }
}
