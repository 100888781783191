import { Type } from "class-transformer";
import { ServiceStatus } from "../enums/service.status";
import BaseModel from "../models/base/base.model";

export class ResponseTechnicianServiceModel {
    id!: number;

    vesselNumber!: string;

    vesselId!: number;

    name!: string;

    serviceName?: string;

    serviceStatus!: ServiceStatus;

    type?: BaseModel;

    necessity?: BaseModel;

    typeName?: string;

    serviceProviderId: number | undefined;

    serviceProviderName!: string;

    secondaryServiceProviderId?: number;

    @Type(() => Date)
    nextServiceDate!: Date;

    get nextServiceDateString(): string {
        return this.nextServiceDate.toString().serializedToLocalDateString();
    }

    handlerId?: number;

    handlerName?: string;

    secondaryHandlerId?: number;

    country?: string;

    isLocked: boolean = false;

    [others: string]: unknown;
}
