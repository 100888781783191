const words: string[] = ["application/msword", "application/rtf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];

const excels: string[] = ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];

const pdfs: string[] = ["application/pdf"];

const audios: string[] = ["audio/mpeg", "audio/x-wav", "audio/wav"];

export const images: string[] = ["image/bmp", "image/gif", "image/jpeg", "image/pipeg", "image/png", "image/svg+xml"];

export const videos: string[] = ["video/mp4", "video/mpeg", "video/quicktime", "video/x-msvideo"];

const texts: string[] = ["text/plain"];

const zips: string[] = [
    "application/zip",
    "application/x-zip-compressed",
    "multipart/x-zip",
    "application/vnd.rar",
    "application/x-compressed",
    "application/x-rar-compressed",
    "application/octet-stream"
];

export const imageHelper = {
    getImageUrlFromType(type: string): string {
        switch (true) {
            case words.includes(type):
                return require("../../../public/files/doc.png");
            case excels.includes(type):
                return require("../../../public/files/excel.png");
            case pdfs.includes(type):
                return require("../../../public/files/pdf.png");
            case audios.includes(type):
                return require("../../../public/files/audio.png");
            case images.includes(type):
                return require("../../../public/files/image.png");
            case videos.includes(type):
                return require("../../../public/files/video.png");
            case texts.includes(type):
                return require("../../../public/files/text.png");
            case zips.includes(type):
                return require("../../../public/files/zip-rar.svg");
            default:
                return "";
        }
    }
};

export const isImage = (type: string): boolean => {
    return images.includes(type);
};

export const isVideo = (type: string): boolean => {
    return videos.includes(type);
};
