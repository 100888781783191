import { Type } from "class-transformer";

export class ResponsePartFullModel {
    id!: number;

    productionOrderLogId?: string;

    partNumber!: string;

    description?: string;

    quantity?: number;

    get quantityString(): string | undefined {
        return this.quantity?.toString() + " pcs";
    }

    total?: number;

    position?: string;

    get totalString(): string | undefined {
        return this.total?.toString() + " pcs";
    }

    revisionNumber?: string;
    drawingNumber?: string;

    @Type(() => Date)
    installDate!: Date;
}
