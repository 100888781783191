import httpClient from "../utilities/helpers/http.client.axios";
import { AxiosInstance, AxiosResponse } from "axios";
import { GlobalFieldType } from "@/models/enums/global.field.type";
import { GlobalTableModel } from "@/models/models/global.table.model";
import { CountryModel } from "@/models/models/country.model";

export class DictionaryDataManager {
    private readonly api: AxiosInstance = httpClient;

    public async getGlobalTableFieldsAsync(fieldType?: GlobalFieldType): Promise<GlobalTableModel[] | Error> {
        try {
            const response: AxiosResponse<GlobalTableModel[]> = await this.api.get(
                `/DictionaryData/GetGlobalTableFields/${fieldType ?? ""}`
            );
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getCountriesAsync(): Promise<CountryModel[] | Error> {
        try {
            const response: AxiosResponse<CountryModel[]> = await this.api.get("/DictionaryData/GetCountries");
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getVesselSearchableColumnsAsync(isHighClassFleet: boolean): Promise<{ key: string; value: string }[] | Error> {
        try {
            const response: AxiosResponse<{ key: string; value: string }[]> = await this.api.get(`/DictionaryData/GetVesselSearchableColumns/${isHighClassFleet}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getVesselDatesSearchableColumnsAsync(): Promise<{ key: string; value: string }[] | Error> {
        try {
            const response: AxiosResponse<{ key: string; value: string }[]> = await this.api.get("/DictionaryData/GetVesselDatesSearchableColumns");
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getServiceSearchableColumnsAsync(): Promise<{ key: string; value: string }[] | Error> {
        try {
            const response: AxiosResponse<{ key: string; value: string }[]> = await this.api.get("/DictionaryData/GetServiceSearchableColumns");
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getServiceDatesSearchableColumnsAsync(): Promise<{ key: string; value: string }[] | Error> {
        try {
            const response: AxiosResponse<{ key: string; value: string }[]> = await this.api.get("/DictionaryData/GetServiceDatesSearchableColumns");
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getEventLogSearchableColumnsAsync(): Promise<{ key: string; value: string }[] | Error> {
        try {
            const response: AxiosResponse<{ key: string; value: string }[]> = await this.api.get("/DictionaryData/GetEventLogSearchableColumns");
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getEventLogDatesSearchableColumnsAsync(): Promise<{ key: string; value: string }[] | Error> {
        try {
            const response: AxiosResponse<{ key: string; value: string }[]> = await this.api.get(
              "/DictionaryData/GetEventLogDatesSearchableColumns"
            );
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }
}
export const dictionaryDataManager = new DictionaryDataManager();
